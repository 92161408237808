import React from 'react'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Button, Link, Modal, OpenNewIcon, Text } from 'uikit'
import useTheme from 'hooks/useTheme'
import { SWAP_URL } from 'config'

interface NotEnoughTokensModalProps {
  tokenSymbol: string
  tokenAddress: string
  onDismiss?: () => void
}

const StyledLink = styled( Link )`
  width: 100%;
`

const NotEnoughTokensModal: React.FC<NotEnoughTokensModalProps> = ( { tokenSymbol, tokenAddress, onDismiss } ) => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <Modal
      title={ t( '%symbol% required', { symbol: tokenSymbol } ) }
      onDismiss={ onDismiss }
      headerBackground={ theme.colors.gradients.bubblegum }
    >
      <Text color="failure" bold>
        { t( 'Insufficient %symbol% balance', { symbol: tokenSymbol } ) }
      </Text>
      <Text mt="24px">{ t( 'You’ll need %symbol% to stake in this pool!', { symbol: tokenSymbol } ) }</Text>
      <Text>
        { t( 'Buy some %symbol%, or make sure your %symbol% isn’t in another pool or LP.', {
          symbol: tokenSymbol,
        } ) }
      </Text>
      <Button mt="24px" as="a" external href={`${ SWAP_URL }/swap?outputCurrency=${ tokenAddress }`}>
        { t( 'Buy' ) } { tokenSymbol }
      </Button>
      <Button variant="text" onClick={ onDismiss }>
        { t( 'Close Window' ) }
      </Button>
    </Modal>
  )
}

export default NotEnoughTokensModal
