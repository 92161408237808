import { MenuItemsType } from 'uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: ( t: ContextApi['t'] ) => ConfigMenuItemsType[] = ( t ) => [
  {
    label: t( 'Farms' ),
    href: '/farms',
    icon: 'Farm',
    items: [],
  },
  {
    label: t( 'Pools' ),
    href: '/pools',
    icon: 'Pool',
    items: [],
  },
]

export default config
