import React from 'react'
import tokens from 'config/constants/tokens'
import { formatNumber } from 'utils/formatBalance'
import { darkColors, lightColors } from '../../theme/colors'
import { Box, Flex } from '../Box'
import { StyledFooter, StyledIconMobileContainer, StyledList, StyledListItem, StyledSocialLinks, StyledText, StyledToolsContainer } from './styles'
import { FooterProps } from './types'
import CakePrice from '../CakePrice/CakePrice'
import { ArrowForwardIcon, LogoWithTextIcon } from '../Svg'
import { Button } from '../Button'
import { Colors } from '../..'
import { SWAP_URL } from '../../../config'

const MenuItem: React.FC<FooterProps> = ( {
                                            isDark,
                                            cakePriceUsd,
                                            buyCakeLabel,
                                            totalValueLocked,
                                            ...props
                                          } ) => {
  const tvlString = totalValueLocked ? formatNumber( totalValueLocked, 2 ) : '_'

  return (
    <StyledFooter p={ [ '40px 16px', null, '35px 40px 32px 40px' ] } { ...props } justifyContent="center">
      <Flex flexDirection="column" width={ [ '100%', null, '1200px;' ] }>
        <StyledIconMobileContainer display={ [ 'block', null, 'none' ] }>
          <LogoWithTextIcon isDark={ isDark } width="130px"/>
        </StyledIconMobileContainer>
        <Flex
          order={ [ 2, null, 1 ] }
          flexDirection={ [ 'column', null, 'row' ] }
          justifyContent="space-between"
          alignItems="flex-start"
          mb={ [ '42px', null, '36px' ] }
        >
          <StyledList>
            <StyledListItem>Total Value Locked</StyledListItem>
            <StyledText>${ tvlString }</StyledText>
          </StyledList>
          <Box display={ [ 'none', null, 'block' ] }>
            <LogoWithTextIcon isDark={ isDark } width="160px"/>
          </Box>
        </Flex>
        <StyledToolsContainer
          order={ [ 1, null, 3 ] }
          flexDirection={ [ 'column', null, 'row' ] }
          justifyContent="space-between"
        >
          <Flex order={ [ 1, null, 2 ] } mb={ [ '24px', null, '0' ] } justifyContent="space-between" alignItems="center">
            <Box mr="20px">
              <CakePrice cakePriceUsd={ cakePriceUsd } color={ darkColors.textSubtle as keyof Colors }/>
            </Box>
            <Button
              as="a"
              href={ `${ SWAP_URL }/swap?outputCurrency=${ tokens.goge.address }` }
              target="_blank"
              scale="sm"
              endIcon={ <ArrowForwardIcon color={ lightColors.backgroundAlt }/> }
            >
              { buyCakeLabel }
            </Button>
          </Flex>
        </StyledToolsContainer>
      </Flex>
    </StyledFooter>
  )
}

export default MenuItem
