import React from 'react'
import styled from 'styled-components'
import { Flex, Modal, Text, WarningIcon } from 'uikit'
import { useTranslation } from 'contexts/Localization'

interface DepositDisabledModalProps {
  onDismiss?: () => void
}

const MessageWrapper = styled( Flex )`
  width: 45rem;
`

const DepositDisabledModal: React.FC<DepositDisabledModalProps> = ( props ) => {
  const {
    onDismiss,
  } = props

  const { t } = useTranslation()

  return (
    <Modal title={ t( 'Notice' ) } titleIcon={ <WarningIcon /> } onDismiss={ onDismiss }>
      <MessageWrapper>
        <Text color="textSubtle">
          Deposits into the 15, 30 and 60 day pools have been suspended in preparation for a transition to a new staking system.<br/><br/>
          You can continue earning rewards on any GOGE you currently have staked.
        </Text>
      </MessageWrapper>
    </Modal>
  )
}

export default DepositDisabledModal