import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { BalanceInput, Button, ButtonMenu, ButtonMenuItem, Flex, HelpIcon, Modal, Text, useTooltip } from 'uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import RoiCalculatorFooter from './RoiCalculatorFooter'
import RoiCard from './RoiCard'
import useRoiCalculatorReducer, { CalculatorMode, EditingCurrency } from './useRoiCalculatorReducer'
import AnimatedArrow from './AnimatedArrow'

interface RoiCalculatorModalProps {
  onDismiss?: () => void
  onBack?: () => void
  earningTokenPrice: number
  apr: number
  displayApr?: string
  linkLabel: string
  linkHref: string
  stakingTokenBalance: BigNumber
  stakingTokenSymbol: string
  stakingTokenPrice: number
  earningTokenSymbol?: string
  multiplier?: string
  autoCompoundFrequency?: number
  performanceFee?: number
  isFarm?: boolean
  initialValue?: string
}

const StyledModal = styled( Modal )`
  width: 345px;
  & > :nth-child(2) {
    padding: 0;
  }
`

const ScrollableContainer = styled.div`
  padding: 24px;
  max-height: 500px;
  overflow-y: auto;
  ${ ( { theme } ) => theme.mediaQueries.sm } {
    max-height: none;
  }
`

const FullWidthButtonMenu = styled( ButtonMenu )<{ disabled?: boolean }>`
  width: 100%;

  & > button {
    width: 100%;
  }

  opacity: ${ ( { disabled } ) => (disabled ? 0.5 : 1) };
`

const RoiCalculatorModal: React.FC<RoiCalculatorModalProps> = ( {
  onDismiss,
  onBack,
  earningTokenPrice,
  apr,
  displayApr,
  linkLabel,
  linkHref,
  stakingTokenBalance,
  stakingTokenSymbol,
  stakingTokenPrice,
  multiplier,
  initialValue,
  earningTokenSymbol = 'GOGE',
  autoCompoundFrequency = 0,
  performanceFee = 0,
  isFarm = false,
} ) => {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const balanceInputRef = useRef<HTMLInputElement | null>( null )

  const {
    state,
    setPrincipalFromUSDValue,
    setPrincipalFromTokenValue,
    setStakingDuration,
    toggleEditingCurrency,
    setCalculatorMode,
    setTargetRoi,
  } = useRoiCalculatorReducer( stakingTokenPrice, earningTokenPrice, apr, autoCompoundFrequency, performanceFee )

  const { stakingDuration, editingCurrency } = state.controls
  const { principalAsUSD, principalAsToken } = state.data

  // Auto-focus input on opening modal
  useEffect( () => {
    if ( balanceInputRef.current ) {
      balanceInputRef.current.focus()
    }
  }, [] )

  // If user comes to calculator from staking modal - initialize with whatever they put in there
  useEffect( () => {
    if ( initialValue ) {
      setPrincipalFromTokenValue( initialValue )
    }
  }, [ initialValue, setPrincipalFromTokenValue ] )

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    isFarm
      ? t( '“My Balance” here includes both LP Tokens in your wallet, and LP Tokens already staked in this farm.' )
      : t(
      '“My Balance” here includes both %assetSymbol% in your wallet, and %assetSymbol% already staked in this pool.',
      { assetSymbol: stakingTokenSymbol },
      ),
    { placement: 'top-end', tooltipOffset: [ 20, 10 ] },
  )

  const onBalanceFocus = () => {
    setCalculatorMode( CalculatorMode.ROI_BASED_ON_PRINCIPAL )
  }

  const editingUnit = editingCurrency === EditingCurrency.TOKEN ? stakingTokenSymbol : 'USD'
  const editingValue = editingCurrency === EditingCurrency.TOKEN ? principalAsToken : principalAsUSD
  const conversionUnit = editingCurrency === EditingCurrency.TOKEN ? 'USD' : stakingTokenSymbol
  const conversionValue = editingCurrency === EditingCurrency.TOKEN ? principalAsUSD : principalAsToken
  const onUserInput = editingCurrency === EditingCurrency.TOKEN ? setPrincipalFromTokenValue : setPrincipalFromUSDValue

  return (
    <StyledModal
      title={ t( 'ROI Calculator' ) }
      onDismiss={ onBack || onDismiss }
      onBack={ onBack ?? null }
      headerBackground="gradients.bubblegum"
    >
      <ScrollableContainer>
        <Flex flexDirection="column" mb="8px">
          <Text color="secondary" bold fontSize="12px" textTransform="uppercase">
            { t( '%asset% staked', { asset: stakingTokenSymbol } ) }
          </Text>
          <BalanceInput
            currencyValue={ `${ conversionValue } ${ conversionUnit }` }
            innerRef={ balanceInputRef }
            placeholder="0.00"
            value={ editingValue }
            unit={ editingUnit }
            onUserInput={ onUserInput }
            switchEditingUnits={ toggleEditingCurrency }
            onFocus={ onBalanceFocus }
          />
          <Flex justifyContent="space-between" mt="8px">
            <Button
              scale="xs"
              p="4px 16px"
              width="68px"
              variant="tertiary"
              onClick={ () => setPrincipalFromUSDValue( '100' ) }
            >
              $100
            </Button>
            <Button
              scale="xs"
              p="4px 16px"
              width="68px"
              variant="tertiary"
              onClick={ () => setPrincipalFromUSDValue( '1000' ) }
            >
              $1000
            </Button>
            <Button
              disabled={ stakingTokenBalance.lte( 0 ) || !account }
              scale="xs"
              p="4px 16px"
              width="128px"
              variant="tertiary"
              onClick={ () =>
                setPrincipalFromUSDValue( getBalanceNumber( stakingTokenBalance.times( stakingTokenPrice ) ).toString() )
              }
            >
              { t( 'My Balance' ).toLocaleUpperCase() }
            </Button>
            <span ref={ targetRef }>
              <HelpIcon width="16px" height="16px" color="textSubtle"/>
            </span>
            { tooltipVisible && tooltip }
          </Flex>
          <Text mt="24px" color="secondary" bold fontSize="12px" textTransform="uppercase">
            { t( 'Staked for' ) }
          </Text>
          <FullWidthButtonMenu activeIndex={ stakingDuration } onItemClick={ setStakingDuration } scale="sm">
            <ButtonMenuItem variant="tertiary">{ t( '15D' ) }</ButtonMenuItem>
            <ButtonMenuItem variant="tertiary">{ t( '30D' ) }</ButtonMenuItem>
            <ButtonMenuItem variant="tertiary">{ t( '60D' ) }</ButtonMenuItem>
          </FullWidthButtonMenu>
        </Flex>
        <AnimatedArrow calculatorState={ state }/>
        <Flex>
          <RoiCard
            earningTokenSymbol={ earningTokenSymbol }
            calculatorState={ state }
            setTargetRoi={ setTargetRoi }
            setCalculatorMode={ setCalculatorMode }
          />
        </Flex>
      </ScrollableContainer>
      <RoiCalculatorFooter
        isFarm={ isFarm }
        apr={ apr }
        displayApr={ displayApr }
        autoCompoundFrequency={ autoCompoundFrequency }
        multiplier={ multiplier }
        linkLabel={ linkLabel }
        linkHref={ linkHref }
        performanceFee={ performanceFee }
      />
    </StyledModal>
  )
}

export default RoiCalculatorModal
