import { serializeTokens } from './tokens'
import { PoolCategory, SerializedPoolConfig } from './types'
import { getMasterChefAddress } from '../../utils/addressHelpers'
import { GOGE_PER_BLOCK } from '../index'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.goge,
    earningToken: serializedTokens.goge,
    contractAddress: {
      2001: '',
      2000: getMasterChefAddress(),
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: `${GOGE_PER_BLOCK}`,
    sortOrder: 1,
    isFinished: false,
    isMasterPool: true
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.goge,
    earningToken: serializedTokens.goge,
    contractAddress: {
      2001: '',
      2000: getMasterChefAddress(),
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: `${GOGE_PER_BLOCK}`,
    sortOrder: 1,
    isFinished: false,
    isMasterPool: true
  },
]

export default pools
