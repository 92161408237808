import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem, NotificationDot, Text, Toggle } from 'uikit'
import { useTranslation } from 'contexts/Localization'

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${ Text } {
    margin-left: 8px;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${ ( { theme } ) => theme.mediaQueries.sm } {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${ ( { theme } ) => theme.mediaQueries.sm } {
    margin-left: 16px;
  }
`

const PoolTabButtons = ( { stakedOnly, setStakedOnly, hasStakeInFinishedPools } ) => {
  const { url, isExact } = useRouteMatch()
  const { t } = useTranslation()

  const liveOrFinishedSwitch = (
    <Wrapper>
      <ButtonMenu activeIndex={ isExact ? 0 : 1 } scale="sm" variant="subtle">
        <ButtonMenuItem as={ Link } to={ `${ url }` }>
          { t( 'Live' ) }
        </ButtonMenuItem>
        <NotificationDot show={ hasStakeInFinishedPools }>
          <ButtonMenuItem id="finished-pools-button" as={ Link } to='/pools/history'>
            { t( 'Finished' ) }
          </ButtonMenuItem>
        </NotificationDot>
      </ButtonMenu>
    </Wrapper>
  )

  const stakedOnlySwitch = (
    <ToggleWrapper>
      <Toggle checked={ stakedOnly } onChange={ () => setStakedOnly( !stakedOnly ) } scale="sm"/>
      <Text> { t( 'Staked only' ) }</Text>
    </ToggleWrapper>
  )

  return (
    <ViewControls>
      { stakedOnlySwitch }
      { liveOrFinishedSwitch }
    </ViewControls>
  )
}

export default PoolTabButtons
