import React from 'react'
import { Flex, FlexProps, Text, TimerIcon } from 'uikit'
import styled from 'styled-components'
import formatTimePeriod from 'utils/formatTimePeriod'
import getTimePeriod, { PERIODS } from 'utils/getTimePeriods'

interface CountdownTimerStyleProps extends FlexProps {
  fontSize?: string
  textColor?: string
  iconWidth?: string
}

interface CountdownTimerProps extends CountdownTimerStyleProps {
  timestamp: number
}

const CountdownWrapper = styled( Flex )<CountdownTimerStyleProps>`
  border-radius: 6px;
`

const CountdownTimer: React.FC<CountdownTimerProps> = ( props ) => {
  const { timestamp, ...styleProps } = props

  const calculateTimeLeft = React.useCallback( () => {
    if ( timestamp <= 0 ) return ''

    const nowDate = new Date()

    const nowEpoch = Math.floor( nowDate.getTime() / 1000 )
    const expireEpoch = timestamp

    const diffInSeconds = expireEpoch - nowEpoch

    if ( nowEpoch >= expireEpoch ) return ''

    return formatTimePeriod(
      getTimePeriod( diffInSeconds, [ PERIODS.YEARS, PERIODS.MONTHS ] ),
      [ 'seconds' ],
    )
  }, [ timestamp ] )

  const [ timeLeft, setTimeLeft ] = React.useState( calculateTimeLeft() )

  React.useEffect( () => {
    const interval = setInterval( () => setTimeLeft( calculateTimeLeft() ), (1000) )
    return () => clearInterval( interval )
  }, [ calculateTimeLeft, timestamp ] )

  return timeLeft ? (
    <CountdownWrapper { ...styleProps }>
      <TimerIcon width={ styleProps?.iconWidth } color="secondary" mr="4px"/>
      <Text fontSize={ styleProps?.fontSize } color={ styleProps?.textColor }>{ timeLeft }</Text>
    </CountdownWrapper>
  ) : null
}

CountdownWrapper.defaultProps = {
  fontSize: '12px',
  textColor: 'textDisabled',
  iconWidth: '16px',
}

export default CountdownTimer
