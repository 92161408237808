import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import isArchivedPid from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import { fetchFarmUserAllowances, fetchFarmUserEarnings, fetchFarmUserLastDepositTime, fetchFarmUserNextHarvestUntil, fetchFarmUserStakedBalances, fetchFarmUserTokenBalances } from './fetchFarmUser'
import { SerializedFarm, SerializedFarmsState } from '../types'

const noAccountFarmConfig = farmsConfig.map( ( farm ) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
    lastDepositTime: 0,
    nextHarvestUntil: 0
  },
}) )

const initialState: SerializedFarmsState = {
  data: noAccountFarmConfig,
  loadArchivedFarmsData: false,
  userDataLoaded: false,
}

export const nonArchivedFarms = farmsConfig.filter( ( { pid } ) => !isArchivedPid( pid ) )

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<SerializedFarm[], number[]>(
  'farms/fetchFarmsPublicDataAsync',
  async ( pids ) => {
    const farmsToFetch = farmsConfig.filter( ( farmConfig ) => pids.includes( farmConfig.pid ) )

    // Add price helper farms
    const farmsWithPriceHelpers = farmsToFetch.concat( priceHelperLpsConfig )

    const farms = await fetchFarms( farmsWithPriceHelpers )
    const farmsWithPrices = await fetchFarmsPrices( farms )

    // console.debug('fetchFarmsPublicDataAsync', { farmsWithPrices });

    // Filter out price helper LP config farms
    return farmsWithPrices.filter( ( farm: SerializedFarm ) => {
      return farm.pid || farm.pid === 0
    } )
  },
)

interface FarmUserDataResponse {
  pid: number
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  lastDepositTime: number
  nextHarvestUntil: number
}

export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'farms/fetchFarmUserDataAsync',
  async ( { account, pids } ) => {
    const farmsToFetch = farmsConfig.filter( ( farmConfig ) => pids.includes( farmConfig.pid ) )
    const userFarmAllowances = await fetchFarmUserAllowances( account, farmsToFetch )
    const userFarmTokenBalances = await fetchFarmUserTokenBalances( account, farmsToFetch )
    const userStakedBalances = await fetchFarmUserStakedBalances( account, farmsToFetch )
    const userFarmEarnings = await fetchFarmUserEarnings( account, farmsToFetch )
    const userFarmLastDepositTime = await fetchFarmUserLastDepositTime( account, farmsToFetch )
    const userFarmNextHarvestUntil = await fetchFarmUserNextHarvestUntil( account, farmsToFetch )

    return userFarmAllowances.map( ( farmAllowance, index ) => {
      return {
        pid: farmsToFetch[index].pid,
        allowance: userFarmAllowances[index],
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userStakedBalances[index],
        earnings: userFarmEarnings[index],
        lastDepositTime: userFarmLastDepositTime[index],
        nextHarvestUntil: userFarmNextHarvestUntil[index],
      }
    } )
  },
)

export const farmsSlice = createSlice( {
  name: 'Farms',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: ( state, action ) => {
      state.loadArchivedFarmsData = action.payload
    },
  },
  extraReducers: ( builder ) => {
    // Update farms with live data
    builder.addCase( fetchFarmsPublicDataAsync.fulfilled, ( state, action ) => {
      state.data = state.data.map( ( farm ) => {
        const liveFarmData = action.payload.find( ( farmData ) => farmData.pid === farm.pid )
        return { ...farm, ...liveFarmData }
      } )
    } )

    // Update farms with user data
    builder.addCase( fetchFarmUserDataAsync.fulfilled, ( state, action ) => {
      action.payload.forEach( ( userDataEl ) => {
        const { pid } = userDataEl
        const index = state.data.findIndex( ( farm ) => farm.pid === pid )
        state.data[index] = { ...state.data[index], userData: userDataEl }
      } )
      state.userDataLoaded = true
    } )
  },
} )

// Actions
export const { setLoadArchivedFarmsData } = farmsSlice.actions

export default farmsSlice.reducer
