import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wwdoge: new Token(
    MAINNET,
    '0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101',
    18,
    'WWDOGE',
    'Wrapped WDOGE',
    'https://www.binance.com/',
  ),
  usdt: new Token(
    MAINNET,
    '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  goge: new Token(
    MAINNET,
    '0xCe35908DaE2E6B2bAdC617CF8F99249b17a3f3a9',
    9,
    'GOGE',
    'GOGE',
    'https://goge.dog/',
  ),
  dc: new Token(
    MAINNET,
    '0x7B4328c127B85369D9f82ca0503B000D09CF9180',
    18,
    'DC',
    'Dogechain Token',
    'https://dogechain.dog/',
  ),
}

export const testnetTokens = {
  wwdoge: new Token(
    TESTNET,
    '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
    18,
    'WBNB',
    'Wrapped WDOGE',
    'https://www.binance.com/',
  ),
  usdt: new Token(
    TESTNET,
    '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if ( parseInt( chainId, 10 ) === ChainId.TESTNET ) {
    return Object.keys( mainnetTokens ).reduce( ( accum, key ) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} )
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys( unserializedTokens ).reduce( ( accum, key ) => {
    return { ...accum, [key]: serializeToken( unserializedTokens[key] ) }
  }, {} )

  return serializedTokens
}

export default tokens()
