import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ( props ) => {
  return (
    <Svg viewBox="0 0 620 620" { ...props }>
      <image href="/images/logo/logotype-square.png"/>
    </Svg>
  )
}

export default Icon
