import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT } from 'config'
import getGasPrice from 'utils/getGasPrice'
import { BIG_TEN } from '../bigNumber'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeFarm = async ( masterChefContract, pid, amount, decimals = 18 ) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber( amount ).times( BIG_TEN.pow( decimals ) ).toString()

  const tx = await masterChefContract.deposit( pid, value, { ...options, gasPrice } )
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeFarm = async ( masterChefContract, pid, amount, decimals = 18 ) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber( amount ).times( BIG_TEN.pow( decimals ) ).toString()

  const tx = await masterChefContract.withdraw( pid, value, { ...options, gasPrice } )
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestFarm = async ( masterChefContract, pid ) => {
  const gasPrice = getGasPrice()

  const tx = await masterChefContract.deposit( pid, '0', { ...options, gasPrice } )
  const receipt = await tx.wait()
  return receipt.status
}

export const emergencyWithdrawFarm = async ( masterChefContract, pid ) => {
  const gasPrice = getGasPrice()

  const tx = await masterChefContract.emergencyWithdraw( pid, { gasPrice } )

  const receipt = await tx.wait()
  return receipt.status
}
