/**
 * This file contains the base interfaces used to define
 * the Pools manually (at `constants/pools.ts`).
 */

import { Token } from '@pancakeswap/sdk'
import { FeesProps } from '../../state/types'

export interface Address {
  2000: string
  2001?: string
}

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
  projectLink?: string
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native WDOGE behave differently than pools using a token
  'AUTO' = 'Auto',
}

interface FarmConfigBaseProps {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  multiplier?: string
  withdrawLockPeriod?: number
  isCommunity?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
  fees?: FeesProps
  hidden?: boolean
  disableDeposits?: boolean
}

export interface SerializedFarmConfig extends FarmConfigBaseProps {
  token: SerializedToken
  quoteToken: SerializedToken
}

export interface DeserializedFarmConfig extends FarmConfigBaseProps {
  token: Token // aka reward token, or token earned when staking
  quoteToken: Token
}

interface PoolConfigBaseProps {
  sousId: number
  contractAddress: Address
  poolCategory: PoolCategory
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
  enableEmergencyWithdraw?: boolean
  isMasterPool?: boolean
  disableDeposits?: boolean
}

export interface SerializedPoolConfig extends PoolConfigBaseProps {
  earningToken: SerializedToken
  stakingToken: SerializedToken
}

export interface DeserializedPoolConfig extends PoolConfigBaseProps {
  earningToken: Token
  stakingToken: Token
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}