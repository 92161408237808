import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'
import { BIG_ZERO } from 'utils/bigNumber'
import { Box, Flex, Text } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { PoolCategory } from 'config/constants/types'
import { DeserializedPool } from 'state/types'
import ApprovalAction from './ApprovalAction'
import StakeActions from './StakeActions'
import HarvestActions from './HarvestActions'

const InlineText = styled( Text )`
  display: inline;
`

interface CardActionsProps {
  pool: DeserializedPool
  stakedBalance: BigNumber
}

const CardActions: React.FC<CardActionsProps> = ( { pool, stakedBalance } ) => {
  const {
    sousId,
    stakingToken,
    earningToken,
    harvest,
    poolCategory,
    userData,
    earningTokenPrice,
    withdrawLockPeriod,
    isMasterPool,
    disableDeposits,
  } = pool

  const { t } = useTranslation()

  // Pools using native BNB behave differently than pools using a token
  const isBnbPool = poolCategory === PoolCategory.BINANCE

  const allowance = userData?.allowance ? new BigNumber( userData.allowance ) : BIG_ZERO
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber( userData.stakingTokenBalance ) : BIG_ZERO
  const earnings = userData?.pendingReward ? new BigNumber( userData.pendingReward ) : BIG_ZERO
  const needsApproval = !allowance.gt( 0 ) && !isBnbPool
  const isStaked = stakedBalance.gt( 0 )
  const isLoading = !userData

  const userUnstakeLockedUntil = (withdrawLockPeriod > 0 && userData?.lastDepositTime > 0) ? withdrawLockPeriod + userData?.lastDepositTime : 0
  const userHarvestLockedUntil = userData?.nextHarvestUntil

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        { harvest && (
          <>
            <Box display="inline">
              <InlineText color="secondary" textTransform="uppercase" bold fontSize="12px">
                { `${ earningToken.symbol } ` }
              </InlineText>
              <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                { t( 'Earned' ) }
              </InlineText>
            </Box>
            <HarvestActions
              earnings={ earnings }
              earningToken={ earningToken }
              sousId={ sousId }
              isMasterPool={ isMasterPool }
              earningTokenPrice={ earningTokenPrice }
              userHarvestLockedUntil={ userHarvestLockedUntil }
              disableDeposits={ disableDeposits }
              stakedBalance={ stakedBalance }
              isBnbPool={ isBnbPool }
              isLoading={ isLoading }
            />
          </>
        ) }
        <Box display="inline">
          <InlineText color={ isStaked ? 'secondary' : 'textSubtle' } textTransform="uppercase" bold fontSize="12px">
            { isStaked ? stakingToken.symbol : t( 'Stake' ) }{ ' ' }
          </InlineText>
          <InlineText color={ isStaked ? 'textSubtle' : 'secondary' } textTransform="uppercase" bold fontSize="12px">
            { isStaked ? t( 'Staked' ) : `${ stakingToken.symbol }` }
          </InlineText>
        </Box>
        { needsApproval ? (
          <ApprovalAction pool={ pool } isLoading={ isLoading }/>
        ) : (
          <StakeActions
            isLoading={ isLoading }
            pool={ pool }
            stakingTokenBalance={ stakingTokenBalance }
            stakedBalance={ stakedBalance }
            isBnbPool={ isBnbPool }
            isStaked={ isStaked }
            userUnstakeLockedUntil={ userUnstakeLockedUntil }
            disableDeposits={ disableDeposits }
          />
        ) }
      </Flex>
    </Flex>
  )
}

export default CardActions
