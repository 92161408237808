import React from 'react'
import { LockIcon, Skeleton, Tag, TagProps } from 'uikit'

export interface LockTimeProps extends TagProps {
  seconds: number
}

const SECONDS_IN_A_DAY = 86400
const secondsToDays = ( seconds ) => seconds < SECONDS_IN_A_DAY ? 'Less than a day' : `${ Math.floor( seconds / SECONDS_IN_A_DAY ) }D`;

const LockTimeTag: React.FC<LockTimeProps> = ( props ) => {
  const { seconds } = props

  return (
    typeof seconds !== 'undefined' ?
      <Tag variant="secondary" startIcon={ <LockIcon width="12px" color="secondary" mr="4px"/> } { ...props }>
        { seconds <= 0 ? '-' : secondsToDays( seconds ) }
      </Tag> :
      <Skeleton ml="4px" width={ 42 } height={ 28 }/>
  )
}

export default LockTimeTag
