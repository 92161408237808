import React, { useState } from 'react'
import { AutoRenewIcon, Button, Flex, Modal, Text } from 'uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import { DeserializedPool } from 'state/types'
import ModalFooterNote from 'components/ModalFooterNote'
import { formatNumber, getBalanceNumber } from 'utils/formatBalance'
import { BIG_ZERO } from 'utils/bigNumber'
import { usePoolEmergencyWithdraw } from '../../../hooks/useUnstakePool'

interface EmergencyWithdrawModalProps {
  pool: DeserializedPool
  onDismiss?: () => void
}

const EmergencyWithdrawModal: React.FC<EmergencyWithdrawModalProps> = ( props ) => {
  const {
    pool,
    onDismiss,
  } = props

  const {
    sousId,
    stakingToken,
    earningToken,
    isMasterPool,
    userData,
  } = pool

  const { t } = useTranslation()
  const { theme } = useTheme()
  const { onEmergencyWithdraw } = usePoolEmergencyWithdraw( sousId, isMasterPool )
  const { toastSuccess, toastError } = useToast()
  const [ pendingTx, setPendingTx ] = useState( false )

  const stakedBalance = userData?.stakedBalance ? new BigNumber( userData.stakedBalance ) : BIG_ZERO
  const stakedBalanceAsNumber = getBalanceNumber( stakedBalance, stakingToken.decimals )

  const earnings = userData?.pendingReward ? new BigNumber( userData.pendingReward ) : BIG_ZERO
  const earningsAsNumber = getBalanceNumber( earnings, earningToken.decimals )

  const handleConfirmClick = async () => {
    setPendingTx( true )

    try {
      await onEmergencyWithdraw()

      toastSuccess(
        `${ t( 'Withdrew' ) }!`,
        t( 'Your %symbol% tokens have been sent to your wallet!', {
          symbol: earningToken.symbol,
        } ),
      )

      setPendingTx( false )
      onDismiss()
    } catch ( e ) {
      console.error( e )
      toastError( t( 'Error' ), t( 'Please try again. Confirm the transaction and make sure you are paying enough gas!' ) )
      setPendingTx( false )
    }
  }

  return (
    <Modal
      minWidth="346px"
      title={ t( 'Emergency withdraw' ) }
      onDismiss={ onDismiss }
      headerBackground={ theme.colors.gradients.bubblegum }
    >
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text bold>{ t( 'Emergency Withdrawing' ) }:</Text>
        <Flex alignItems="center" minWidth="70px">
          <Text ml="4px" bold>
            { stakingToken.symbol }
          </Text>
        </Flex>
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text bold>
          { t( '%symbol% Balance', {
            symbol: stakingToken.symbol,
          } ) }:
        </Text>
        <Flex alignItems="center" minWidth="70px">
          <Text ml="4px" bold>
            { formatNumber( stakedBalanceAsNumber, 3, 3 ) }
          </Text>
        </Flex>
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text bold color='failure'>
          { t( '%symbol% Rewards', {
            symbol: earningToken.symbol,
          } ) }:
        </Text>
        <Flex alignItems="center" minWidth="70px">
          <Text ml="4px" bold color='failure'>
            { formatNumber( earningsAsNumber, 5, 5 ) }
          </Text>
        </Flex>
      </Flex>

      <ModalFooterNote mt="16px" title="Note" iconColor='failure'>
        <Text fontSize="14px" color="textSubtle" style={ { whiteSpace: 'pre-wrap' } }>
          { t( 'If you make an emergency withdrawal you will not receive\nyour pending rewards.' ) }
        </Text>
      </ModalFooterNote>

      <Button
        isLoading={ pendingTx }
        endIcon={ pendingTx ? <AutoRenewIcon spin color="currentColor"/> : null }
        onClick={ handleConfirmClick }
        disabled={ stakedBalanceAsNumber <= 0 }
        mt="24px"
      >
        { pendingTx ? t( 'Confirming' ) : t( 'Confirm' ) }
      </Button>
    </Modal>
  )
}

export default EmergencyWithdrawModal
