import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { SerializedFarm } from 'state/types'
import tokens from 'config/constants/tokens'
import { FARM_USDT_WWDOGE_PID } from '../../config'

const getFarmFromTokenSymbol = (
  farms: SerializedFarm[],
  tokenSymbol: string,
  preferredQuoteTokens?: string[],
): SerializedFarm => {
  const farmsWithTokenSymbol = farms.filter( ( farm ) => farm.token.symbol === tokenSymbol )
  return filterFarmsByQuoteToken( farmsWithTokenSymbol, preferredQuoteTokens )
}

const getFarmBaseTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,
): BigNumber => {
  const hasTokenPriceVsQuote = Boolean( farm.tokenPriceVsQuote )

  if ( farm.quoteToken.symbol === tokens.usdt.symbol ) {
    return hasTokenPriceVsQuote ? new BigNumber( farm.tokenPriceVsQuote ) : BIG_ZERO
  }

  if ( farm.quoteToken.symbol === tokens.wwdoge.symbol ) {
    return hasTokenPriceVsQuote ? bnbPriceBusd.times( farm.tokenPriceVsQuote ) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for USD/BNB farms
  if ( !quoteTokenFarm ) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't USD or WBNB, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - BNB, (pBTC - BNB)
  // from the BNB - pBTC price, we can calculate the PNT - USD price
  if ( quoteTokenFarm.quoteToken.symbol === tokens.wwdoge.symbol ) {
    const quoteTokenInBusd = bnbPriceBusd.times( quoteTokenFarm.tokenPriceVsQuote )
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber( farm.tokenPriceVsQuote ).times( quoteTokenInBusd )
      : BIG_ZERO
  }

  if ( quoteTokenFarm.quoteToken.symbol === tokens.usdt.symbol ) {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInBusd
      ? new BigNumber( farm.tokenPriceVsQuote ).times( quoteTokenInBusd )
      : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed USD/WBNB quoteToken
  return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  bnbPriceBusd: BigNumber,
): BigNumber => {
  if ( farm.quoteToken.symbol === tokens.usdt.symbol ) {
    return BIG_ONE
  }

  if ( farm.quoteToken.symbol === tokens.wwdoge.symbol ) {
    return bnbPriceBusd
  }

  if ( !quoteTokenFarm ) {
    return BIG_ZERO
  }

  if ( quoteTokenFarm.quoteToken.symbol === tokens.wwdoge.symbol ) {
    return quoteTokenFarm.tokenPriceVsQuote ? bnbPriceBusd.times( quoteTokenFarm.tokenPriceVsQuote ) : BIG_ZERO
  }

  if ( quoteTokenFarm.quoteToken.symbol === tokens.usdt.symbol ) {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber( quoteTokenFarm.tokenPriceVsQuote ) : BIG_ZERO
  }

  return BIG_ZERO
}

const fetchFarmsPrices = async ( farms: SerializedFarm[] ) => {
  const nativeCurrencyVsUsdtFarm = farms.find( ( farm ) => farm.pid === FARM_USDT_WWDOGE_PID )
  const nativeCurrencyPriceUsdt = nativeCurrencyVsUsdtFarm.tokenPriceVsQuote ? BIG_ONE.div( nativeCurrencyVsUsdtFarm.tokenPriceVsQuote ) : BIG_ZERO

  return farms.map( ( farm ) => {
    const quoteTokenFarm = getFarmFromTokenSymbol( farms, farm.quoteToken.symbol )
    const tokenPriceBusd = getFarmBaseTokenPrice( farm, quoteTokenFarm, nativeCurrencyPriceUsdt )
    const quoteTokenPriceBusd = getFarmQuoteTokenPrice( farm, quoteTokenFarm, nativeCurrencyPriceUsdt )

    // console.log( {
    //   farmPid: farm.pid,
    //   stakingToken: farm.token.symbol,
    //   stakingTokenPriceBusd: farm.tokenPriceBusd,
    //   quoteToken: farm.quoteToken.symbol,
    //   quoteTokenPriceBusd: farm.quoteTokenPriceBusd,
    //   nativeCurrencyPriceUsdt: nativeCurrencyPriceUsdt.toString(),
    // } )

    return {
      ...farm,
      tokenPriceBusd: tokenPriceBusd.toJSON(),
      quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
    }
  } )
}

export default fetchFarmsPrices
