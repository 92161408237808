import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'uikit/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {
  }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Comic Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    background-color: ${ ( { theme } ) => theme.colors.background };

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
