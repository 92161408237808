import React, { useState } from 'react'
import { AutoRenewIcon, Button, ButtonMenu, ButtonMenuItem, Flex, Heading, HelpIcon, Modal, Text, useTooltip } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'
import { Token } from '@pancakeswap/sdk'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import ModalFooterNote from 'components/ModalFooterNote'
import { formatNumber } from 'utils/formatBalance'
import useHarvestPool from '../../../hooks/useHarvestPool'
import useStakePool from '../../../hooks/useStakePool'

interface CollectModalProps {
  formattedBalance: string
  fullBalance: string
  earningToken: Token
  earningsDollarValue: number
  sousId: number
  isMasterPool: boolean
  isBnbPool: boolean
  isCompoundPool?: boolean
  disableDeposits?: boolean
  stakedBalance?: BigNumber
  onDismiss?: () => void
}

const CollectModal: React.FC<CollectModalProps> = ( props ) => {
  const {
    formattedBalance,
    fullBalance,
    earningToken,
    earningsDollarValue,
    sousId,
    isMasterPool,
    isBnbPool,
    isCompoundPool = false,
    disableDeposits = false,
    stakedBalance,
    onDismiss,
  } = props
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { toastSuccess, toastError } = useToast()
  const { onReward } = useHarvestPool( sousId, isMasterPool, isBnbPool )
  const { onStake } = useStakePool( sousId, isMasterPool, isBnbPool )
  const [ pendingTx, setPendingTx ] = useState( false )
  const [ shouldCompound, setShouldCompound ] = useState( false )
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    <>
      <Text mb="12px">{ t( 'Collect: collect GOGE and send to wallet' ) }</Text>
      <Text>{ t( 'Compound: collect and restake GOGE into pool.' ) }</Text>
    </>,
    { placement: 'bottom-end', tooltipOffset: [ 20, 10 ] },
  )

  const handleHarvestConfirm = async () => {
    setPendingTx( true )
    // compounding
    if ( shouldCompound && !disableDeposits ) {
      try {
        await onStake( fullBalance, earningToken.decimals )
        toastSuccess(
          `${ t( 'Compounded' ) }!`,
          t( 'Your %symbol% earnings have been re-invested into the pool!', { symbol: earningToken.symbol } ),
        )
        setPendingTx( false )
        onDismiss()
      } catch ( e ) {
        toastError( t( 'Error' ), t( 'Please try again. Confirm the transaction and make sure you are paying enough gas!' ) )
        console.error( e )
        setPendingTx( false )
      }
    } else {
      // harvesting or just 'collecting'
      try {
        await onReward()
        toastSuccess(
          `${ t( 'Collected' ) }!`,
          t( 'Your %symbol% earnings have been sent to your wallet!', { symbol: earningToken.symbol } ),
        )
        setPendingTx( false )
        onDismiss()
      } catch ( e ) {
        toastError( t( 'Error' ), t( 'Please try again. Confirm the transaction and make sure you are paying enough gas!' ) )
        console.error( e )
        setPendingTx( false )
      }
    }
  }

  return (
    <Modal
      title={ `${ earningToken.symbol } ${ t( 'Collect' ) }` }
      onDismiss={ onDismiss }
      headerBackground={ theme.colors.gradients.bubblegum }
    >
      { isCompoundPool && !disableDeposits && (
        <Flex justifyContent="center" alignItems="center" mb="24px">
          <ButtonMenu
            activeIndex={ shouldCompound ? 1 : 0 }
            scale="sm"
            variant="subtle"
            onItemClick={ ( index ) => setShouldCompound( !!index ) }
          >
            <ButtonMenuItem as="button">{ t( 'Collect' ) }</ButtonMenuItem>
            <ButtonMenuItem as="button">{ t( 'Compound' ) }</ButtonMenuItem>
          </ButtonMenu>
          <Flex ml="10px" ref={ targetRef }>
            <HelpIcon color="textSubtle"/>
          </Flex>
          { tooltipVisible && tooltip }
        </Flex>
      ) }

      <Flex justifyContent="space-between" flexDirection="column" alignItems="flex-start" mb="24px">
        <Text>{ shouldCompound ? t( 'Compounding' ) : t( 'Collecting' ) }:</Text>
        <Flex flexDirection="column">
          <Heading>
            { formattedBalance } { earningToken.symbol }
          </Heading>
          { earningsDollarValue > 0 && (
            <Text fontSize="12px" color="textSubtle">{ `~${ formatNumber( earningsDollarValue ) } USD` }</Text>
          ) }
        </Flex>
      </Flex>

      <ModalFooterNote title="Re-lock warning">
        { shouldCompound && (
          <Text fontSize="14px" color="textSubtle">
            Compounding will re-lock your staked tokens!
          </Text>
        ) }
        <Text fontSize="14px" color="textSubtle" textWrap>
          To withdraw your earnings plus original staked tokens{ '\n' }use the &apos;Unstake&apos; button.
        </Text>
      </ModalFooterNote>

      <Button
        mt="8px"
        onClick={ handleHarvestConfirm }
        isLoading={ pendingTx }
        endIcon={ pendingTx ? <AutoRenewIcon spin color="currentColor"/> : null }
      >
        { pendingTx ? t( 'Confirming' ) : t( 'Confirm' ) }
      </Button>

      <Button variant="text" onClick={ onDismiss } pb="0px">
        { t( 'Close Window' ) }
      </Button>
    </Modal>
  )
}

export default CollectModal
