import React from 'react'
import { Button, Flex, Skeleton, Text, useModal, useTooltip } from 'uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { DeserializedPool } from 'state/types'
import Balance from 'components/Balance'
import DepositDisabledModal from 'components/DepositDisabledModal'
import NotEnoughTokensModal from '../Modals/NotEnoughTokensModal'
import StakeModal from '../Modals/StakeModal'
import UnstakeModal from '../Modals/UnstakeModal'

interface StakeActionsProps {
  pool: DeserializedPool
  stakingTokenBalance: BigNumber
  stakedBalance: BigNumber
  isBnbPool: boolean
  isStaked: ConstrainBoolean
  isLoading?: boolean
  userUnstakeLockedUntil: number
  disableDeposits?: boolean
}

const StakeAction: React.FC<StakeActionsProps> = ( props ) => {
  const {
    pool,
    stakingTokenBalance,
    stakedBalance,
    isBnbPool,
    isStaked,
    userUnstakeLockedUntil,
    disableDeposits,
    isLoading = false,
  } = props;
  const { stakingToken, stakingTokenPrice, stakingLimit, isFinished, userData } = pool
  const { t } = useTranslation()
  const stakedTokenBalance = getBalanceNumber( stakedBalance, stakingToken.decimals )
  const stakedTokenDollarBalance = getBalanceNumber(
    stakedBalance.multipliedBy( stakingTokenPrice ),
    stakingToken.decimals,
  )

  const [ onPresentTokenRequired ] = useModal( <NotEnoughTokensModal tokenSymbol={ stakingToken.symbol } tokenAddress={ stakingToken.address }/> )

  const [ onPresentStake ] = useModal(
    <StakeModal
      isBnbPool={ isBnbPool }
      pool={ pool }
      stakingTokenBalance={ stakingTokenBalance }
      stakingTokenPrice={ stakingTokenPrice }
    />,
  )

  const [ onPresentDisabledStake ] = useModal( <DepositDisabledModal/> )

  const onClickStake = React.useCallback(
    () => disableDeposits ? onPresentDisabledStake() : onPresentStake(),
    [
      disableDeposits,
      onPresentDisabledStake,
      onPresentStake,
    ],
  )

  const [ onPresentUnstake ] = useModal(
    <UnstakeModal
      pool={ pool }
      stakingTokenPrice={ stakingTokenPrice }
      userUnstakeLockedUntil={userUnstakeLockedUntil}
    />,
  )

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t( 'You’ve already staked the maximum amount you can stake in this pool!' ),
    { placement: 'bottom' },
  )

  const reachStakingLimit = stakingLimit.gt( 0 ) && userData.stakedBalance.gte( stakingLimit )

  const renderStakeAction = () => {
    return isStaked ? (
      <>
        <Flex flexDirection="column" mb="1rem">
          <>
            <Balance bold fontSize="20px" decimals={ 3 } value={ stakedTokenBalance }/>
            { stakingTokenPrice !== 0 && (
              <Text fontSize="12px" color="textSubtle">
                <Balance
                  fontSize="12px"
                  color="textSubtle"
                  decimals={ 2 }
                  value={ stakedTokenDollarBalance }
                  prefix="~"
                  unit=" USD"
                />
              </Text>
            ) }
          </>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="flex-end">
          <Button variant="tertiary" onClick={ onPresentUnstake } mr="6px" style={ { flex: 1 } }>
            { t( 'Unstake' ) }
          </Button>
          { reachStakingLimit ? (
            <span ref={ targetRef }>
              <Button disabled variant="tertiary" mr="6px" style={ { flex: 1 } }>
                { t( 'Unstake' ) }
              </Button>
            </span>
          ) : (
            <Button
              variant="secondary"
              onClick={ stakingTokenBalance.gt( 0 ) ? onClickStake : onPresentTokenRequired }
              disabled={ isFinished }
              style={ { flex: 1 } }
            >
              { t( 'Stake' ) }
            </Button>
          ) }
          { tooltipVisible && tooltip }
        </Flex>
      </>
    ) : (
      <Button disabled={ isFinished } onClick={ stakingTokenBalance.gt( 0 ) ? onClickStake : onPresentTokenRequired }>
        { t( 'Stake' ) }
      </Button>
    )
  }

  return <Flex flexDirection="column">{ isLoading ? <Skeleton width="100%" height="52px"/> : renderStakeAction() }</Flex>
}

export default StakeAction
