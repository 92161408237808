export default {
  masterChef: {
    2001: '',
    2000: '0x99D6C9a5204C0Ab0B29cf8F255A4931E0049b807',
  },
  sousChef: {
    2001: '',
    2000: '',
  },
  multiCall: {
    2001: '',
    2000: '0x903f033E03268C3223941bD52D7609A8B62f7A71',
  },
}
