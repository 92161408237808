import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ( props ) => {
  return (
    <Svg viewBox="0 0 350 350" { ...props }>
        <path d="M259.537,167.271c-12.01-17.444-18.137-30.533-19.77-52.312c-1.223-16.276,5.619-35.071,5.619-35.071
	c-8.48,5.648-8.574,5.043-14.172,9.669c-29.137,24.082-36.75,48.183-37.641,51.299c-0.061,0.221-0.096,0.348-0.096,0.348
	l0.096-0.348c0,0,22.773-89.092-40.621-130.579C147.691,6.835,135.556,0,135.556,0s31.475,77.164-24.879,122.941
	c-11.092-45.271-69.65-45.828-69.65-45.828s4.5,15.773,5.223,19.504c9.49,49.199-19.597,64.768-25.505,111.277
	c0.674-5.338,0.406-3.213-0.004,0.029c-0.002,0.011-0.004,0.023-0.004,0.035c0.001-0.006,0.001-0.01,0.002-0.016
	c-0.002,0.014-0.003,0.027-0.004,0.039c-4.611,36.511,12.787,64.789,39.523,83.869c1.248-12.887,6.531-25.671,13.873-36.338
	c8.309-12.067,12.549-21.122,13.674-36.187c0.84-11.26-3.887-24.264-3.887-24.264c5.858,3.911,5.932,3.493,9.803,6.692
	c16.375,13.534,22.922,27.078,25.133,32.838c0.074-0.045,0.15-0.089,0.23-0.133c-2.793-12.607-10.666-61.735,28.777-87.548
	c3.637-2.377,12.033-7.106,12.033-7.106s-22.336,48.84,26.838,88.511c-1.098-16.236,5.096-31.227,16.687-42.778
	c6.297-6.27,17.645-9.283,17.645-9.283s-3.117,10.918-3.615,13.496c-6.566,34.043,22.014,60.514,15.908,92.76
	c-1.145,6.044-2.785,11.91-5.072,17.489h2.73c14.496-7.741,27.949-17.808,37.162-31.621
	C289.14,236.95,279.719,196.583,259.537,167.271z"/>
    </Svg>
  )
}

export default Icon
