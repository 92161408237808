import getTimePeriods from './getTimePeriods'

export enum FormatMode {
  SHORT_MODE,
  LONG_MODE,
}

const periodNameMap = {
  'years': 'year',
  'months': 'month',
  'weeks': 'week',
  'days': 'day',
  'hours': 'hour',
  'minutes': 'minute',
  'seconds': 'second',
}

/**
 * @param {Object} periods Return value from getTimePeriods
 * @param excludePeriods Key list for the object values of that exclude the periods
 * @param formatMode Long (weeks) or Short (w)
 * @return {string} '14h 3m 4s'
 */
const formatTimePeriod = ( periods: ReturnType<typeof getTimePeriods>, excludePeriods = [], formatMode: FormatMode = FormatMode.SHORT_MODE ) => {
  const textArr = []

  Object.keys( periods ).forEach( ( period ) => {
    if ( periods[period] > 0 && !excludePeriods.includes( period ) ) {
      const periodLength = periods[period]
      let periodName = periodNameMap[period]

      if ( formatMode === FormatMode.SHORT_MODE ) {
        periodName = periodName.substr( 0, 1 )
      } else if ( formatMode === FormatMode.LONG_MODE ) {
        if ( periodLength > 1 ) {
          periodName = `${ periodName }s`
        }
      }

      textArr.push( `${ periodLength } ${ periodName }` )
    }
  } )

  if ( textArr.length === 0 ) {
    return null
  }

  return textArr.join( ' ' )
}

export default formatTimePeriod
