import { ChainId, Currency, currencyEquals, JSBI, Price } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import tokens, { mainnetTokens } from 'config/constants/tokens'
import { PairState, usePairs } from './usePairs'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const BUSD_MAINNET = mainnetTokens.usdt
const { wwdoge: WWDOGE } = tokens

/**
 * Returns the price in USD of the input currency
 * @param currency currency to compute the USD price of
 */
export default function useBUSDPrice( currency?: Currency ): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const wrapped = wrappedCurrency( currency, chainId )
  const tokenPairs: [ Currency | undefined, Currency | undefined ][] = useMemo(
    () => [
      [ chainId && wrapped && currencyEquals( WWDOGE, wrapped ) ? undefined : currency, chainId ? WWDOGE : undefined ],
      [ wrapped?.equals( BUSD_MAINNET ) ? undefined : wrapped, chainId === ChainId.MAINNET ? BUSD_MAINNET : undefined ],
      [ chainId ? WWDOGE : undefined, chainId === ChainId.MAINNET ? BUSD_MAINNET : undefined ],
    ],
    [ chainId, currency, wrapped ],
  )
  const [ [ ethPairState, ethPair ], [ busdPairState, busdPair ], [ busdEthPairState, busdEthPair ] ] = usePairs( tokenPairs )

  return useMemo( () => {
    if ( !currency || !wrapped || !chainId ) {
      return undefined
    }
    // handle weth/eth
    if ( wrapped.equals( WWDOGE ) ) {
      if ( busdPair ) {
        const price = busdPair.priceOf( WWDOGE )
        return new Price( currency, BUSD_MAINNET, price.denominator, price.numerator )
      }
      return undefined
    }
    // handle busd
    if ( wrapped.equals( BUSD_MAINNET ) ) {
      return new Price( BUSD_MAINNET, BUSD_MAINNET, '1', '1' )
    }

    const ethPairETHAmount = ethPair?.reserveOf( WWDOGE )
    const ethPairETHBUSDValue: JSBI =
      ethPairETHAmount && busdEthPair ? busdEthPair.priceOf( WWDOGE ).quote( ethPairETHAmount ).raw : JSBI.BigInt( 0 )

    // all other tokens
    // first try the busd pair
    if (
      busdPairState === PairState.EXISTS &&
      busdPair &&
      busdPair.reserveOf( BUSD_MAINNET ).greaterThan( ethPairETHBUSDValue )
    ) {
      const price = busdPair.priceOf( wrapped )
      return new Price( currency, BUSD_MAINNET, price.denominator, price.numerator )
    }
    if ( ethPairState === PairState.EXISTS && ethPair && busdEthPairState === PairState.EXISTS && busdEthPair ) {
      if ( busdEthPair.reserveOf( BUSD_MAINNET ).greaterThan( '0' ) && ethPair.reserveOf( WWDOGE ).greaterThan( '0' ) ) {
        const ethBusdPrice = busdEthPair.priceOf( BUSD_MAINNET )
        const currencyEthPrice = ethPair.priceOf( WWDOGE )
        const busdPrice = ethBusdPrice.multiply( currencyEthPrice ).invert()
        return new Price( currency, BUSD_MAINNET, busdPrice.denominator, busdPrice.numerator )
      }
    }

    return undefined
  }, [ chainId, currency, ethPair, ethPairState, busdEthPair, busdEthPairState, busdPair, busdPairState, wrapped ] )
}

export const useCakeBusdPrice = (): Price | undefined => {
  return useBUSDPrice( tokens.goge )
}

export const useBNBBusdPrice = (): Price | undefined => {
  return useBUSDPrice( tokens.wwdoge )
}
