import React, { useState } from 'react'
import styled from 'styled-components'
import { AutoRenewIcon, BalanceInput, Button, Flex, Image, Modal, Slider, Text } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import BigNumber from 'bignumber.js'
import { nowEpoch } from 'utils'
import { formatNumber, getDecimalAmount, getFullDisplayBalance } from 'utils/formatBalance'
import { DeserializedPool } from 'state/types'
import ModalFooterNote from 'components/ModalFooterNote'
import CountdownTimer from 'components/CountdownTimer'
import PercentageButton from './PercentageButton'
import useUnstakePool from '../../../hooks/useUnstakePool'

interface UnstakeModalProps {
  pool: DeserializedPool
  stakingTokenPrice: number
  userUnstakeLockedUntil?: number
  onDismiss?: () => void
}

const UnavailableMessage = styled.div`
  background-color: white;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: ${ ( { theme } ) => theme.radii.default };
`

const UnstakeModal: React.FC<UnstakeModalProps> = ( props ) => {
  const {
    pool,
    stakingTokenPrice,
    userUnstakeLockedUntil,
    onDismiss,
  } = props

  const { sousId, stakingToken, userData, earningToken, isMasterPool } = pool

  const { t } = useTranslation()
  const { theme } = useTheme()
  const { onUnstake } = useUnstakePool( sousId, isMasterPool, pool.enableEmergencyWithdraw )
  const { toastSuccess, toastError } = useToast()
  const [ pendingTx, setPendingTx ] = useState( false )
  const [ unstakeAmount, setUnstakeAmount ] = useState( '' )
  const [ percent, setPercent ] = useState( 0 )

  const getCalculatedStakingLimit = () => userData.stakedBalance
  const fullDecimalStakeAmount = getDecimalAmount( new BigNumber( unstakeAmount ), stakingToken.decimals )
  const userNotEnoughToken = userData.stakedBalance.lt( fullDecimalStakeAmount )

  const usdValueStaked = new BigNumber( unstakeAmount ).times( stakingTokenPrice )
  const formattedUsdValueStaked = !usdValueStaked.isNaN() && formatNumber( usdValueStaked.toNumber() )

  const userUnstakeLockTimerEnded = nowEpoch() >= userUnstakeLockedUntil

  const handleStakeInputChange = ( input: string ) => {
    if ( input ) {
      const convertedInput = getDecimalAmount( new BigNumber( input ), stakingToken.decimals )
      const percentage = Math.floor( convertedInput.dividedBy( getCalculatedStakingLimit() ).multipliedBy( 100 ).toNumber() )
      setPercent( Math.min( percentage, 100 ) )
    } else {
      setPercent( 0 )
    }
    setUnstakeAmount( input )
  }

  const handleChangePercent = ( sliderPercent: number ) => {
    if ( sliderPercent > 0 ) {
      const percentageOfStakingMax = getCalculatedStakingLimit().dividedBy( 100 ).multipliedBy( sliderPercent )
      const amountToStake = getFullDisplayBalance( percentageOfStakingMax, stakingToken.decimals, stakingToken.decimals )
      setUnstakeAmount( amountToStake )
    } else {
      setUnstakeAmount( '' )
    }
    setPercent( sliderPercent )
  }

  const handleConfirmClick = async () => {
    setPendingTx( true )

    try {
      await onUnstake( unstakeAmount, stakingToken.decimals )

      toastSuccess(
        `${ t( 'Unstaked' ) }!`,
        t( 'Your %symbol% earnings have also been harvested to your wallet!', {
          symbol: earningToken.symbol,
        } ),
      )

      setPendingTx( false )
      onDismiss()
    } catch ( e ) {
      console.error( e )
      toastError( t( 'Error' ), t( 'Please try again. Confirm the transaction and make sure you are paying enough gas!' ) )
      setPendingTx( false )
    }
  }

  return (
    <Modal
      minWidth="346px"
      title={ t( 'Unstake' ) }
      onDismiss={ onDismiss }
      headerBackground={ theme.colors.gradients.bubblegum }
    >
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text bold>{ t( 'Unstake' ) }:</Text>
        <Flex alignItems="center" minWidth="70px">
          <Image src={ `/images/tokens/${ stakingToken.address }.png` } width={ 24 } height={ 24 } alt={ stakingToken.symbol }/>
          <Text ml="4px" bold>
            { stakingToken.symbol }
          </Text>
        </Flex>
      </Flex>

      { userUnstakeLockTimerEnded ? (
        <>
          <BalanceInput
            value={ unstakeAmount }
            onUserInput={ handleStakeInputChange }
            currencyValue={ stakingTokenPrice !== 0 && `~${ formattedUsdValueStaked || 0 } USD` }
            isWarning={ userNotEnoughToken }
            decimals={ stakingToken.decimals }
          />
          { userNotEnoughToken && (
            <Text color="failure" fontSize="12px" style={ { textAlign: 'right' } } mt="4px">
              { t( 'Insufficient %symbol% balance', {
                symbol: stakingToken.symbol,
              } ) }
            </Text>
          ) }
          <Text ml="auto" color="textSubtle" fontSize="12px" mb="8px">
            { t( 'Balance: %balance%', {
              balance: getFullDisplayBalance( getCalculatedStakingLimit(), stakingToken.decimals ),
            } ) }
          </Text>
          <Slider
            min={ 0 }
            max={ 100 }
            value={ percent }
            onValueChanged={ handleChangePercent }
            name="stake"
            valueLabel={ `${ percent }%` }
            step={ 1 }
          />
          <Flex alignItems="center" justifyContent="space-between" mt="8px">
            <PercentageButton onClick={ () => handleChangePercent( 25 ) }>25%</PercentageButton>
            <PercentageButton onClick={ () => handleChangePercent( 50 ) }>50%</PercentageButton>
            <PercentageButton onClick={ () => handleChangePercent( 75 ) }>75%</PercentageButton>
            <PercentageButton onClick={ () => handleChangePercent( 100 ) }>{ t( 'Max' ) }</PercentageButton>
          </Flex>

          <ModalFooterNote mt="24px" title="Note">
            <Text fontSize="14px" color="textSubtle" textWrap>
              { t( 'If your rewards are unlocked, unstaking will auto-harvest\nyour rewards.' ) }
            </Text>
          </ModalFooterNote>

          <Button
            isLoading={ pendingTx }
            endIcon={ pendingTx ? <AutoRenewIcon spin color="currentColor"/> : null }
            onClick={ handleConfirmClick }
            disabled={ !unstakeAmount || parseFloat( unstakeAmount ) === 0 || userNotEnoughToken }
            mt="24px"
          >
            { pendingTx ? t( 'Confirming' ) : t( 'Confirm' ) }
          </Button>
        </>
      ) : (
        <>
          <UnavailableMessage>
            <Text textAlign="center" style={ { whiteSpace: 'pre-wrap' } }>
              { t( 'You will be able to unstake from this pool in:' ) }
            </Text>
            <CountdownTimer justifyContent="center" timestamp={ userUnstakeLockedUntil }/>
          </UnavailableMessage>

          <ModalFooterNote mt="16px" title="Note">
            <Text fontSize="14px" color="textSubtle" textWrap>
              { t( 'If you need to do an emergency withdraw, you can find it by\nclicking on \'More\' at the bottom of the pool.' ) }
            </Text>
          </ModalFooterNote>
        </>
      ) }

    </Modal>
  )
}

export default UnstakeModal
