import React from 'react'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import Flex from '../../../components/Box/Flex'
import { LogoWithTextIcon, PancakeRoundIcon } from '../../../components/Svg'

interface Props {
  isDark: boolean;
  href: string;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); } 
  50% { transform:  scaleY(0.1); } 
`

const StyledLink = styled( Link )`
  display: flex;
  align-items: center;
  .mobile-icon {
    width: 32px;
    ${ ( { theme } ) => theme.mediaQueries.nav } {
      display: none;
    }
  }
  .desktop-icon {
    width: 153px;
    display: none;
    ${ ( { theme } ) => theme.mediaQueries.nav } {
      display: block;
    }
  }
  .right-eye {
    animation-delay: 20ms;
  }
  &:hover {
    .left-eye,
    .right-eye {
      transform-origin: center 60%;
      animation-name: ${ blink };
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`

const Logo: React.FC<Props> = ( { isDark, href } ) => {
  const isAbsoluteUrl = href.startsWith( 'http' )
  const innerLogo = (
    <>
      <PancakeRoundIcon className="mobile-icon"/>
      <LogoWithTextIcon className="desktop-icon" isDark={ isDark }/>
    </>
  )

  return (
    <Flex>
      { isAbsoluteUrl ? (
        <StyledLink as="a" href={ href } aria-label="DOGE home page">
          { innerLogo }
        </StyledLink>
      ) : (
        <StyledLink to={ href } aria-label="DOGE home page">
          { innerLogo }
        </StyledLink>
      ) }
    </Flex>
  )
}

export default React.memo( Logo, ( prev, next ) => prev.isDark === next.isDark )
