import React from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu } from 'uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/farms/hooks'
import config from './config/config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import { useTotalValueLocked } from '../../hooks/useTotalValueLocked'

const Menu = ( props ) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()

  const totalValueLocked = useTotalValueLocked();

  const activeMenuItem = getActiveMenuItem( { menuConfig: config( t ), pathname } )
  const activeSubMenuItem = getActiveSubMenuItem( { menuItem: activeMenuItem, pathname } )

  return (
    <UikitMenu
      userMenu={ <UserMenu/> }
      globalMenu={ <GlobalSettings/> }
      isDark={ isDark }
      toggleTheme={ toggleTheme }
      currentLang={ currentLanguage.code }
      langs={ languageList }
      setLang={ setLanguage }
      cakePriceUsd={ cakePriceUsd.toNumber() }
      links={ config( t ) }
      subLinks={ activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items }
      footerLinks={ footerLinks( t ) }
      activeItem={ activeMenuItem?.href }
      activeSubItem={ activeSubMenuItem?.href }
      buyCakeLabel={ t( 'Buy GOGE' ) }
      totalValueLocked={ totalValueLocked }
      { ...props }
    />
  )
}

export default Menu
