import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { FARM_USDT_WWDOGE_PID, FARM_GOGE_WWDOGE_PID } from '../index'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'GOGE',
    lpAddresses: {
      2001: '',
      2000: serializedTokens.goge.address,
    },
    token: serializedTokens.goge,
    quoteToken: serializedTokens.goge,
  },
  {
    pid: FARM_GOGE_WWDOGE_PID,
    lpSymbol: 'GOGE-WWDOGE',
    lpAddresses: {
      2001: '',
      2000: '0xe2ea9a7796ef361c0cf4a02133fee60fdfb5c05b',
    },
    token: serializedTokens.goge,
    quoteToken: serializedTokens.wwdoge
  },
  {
    pid: 4,
    lpSymbol: 'GOGE-WWDOGE',
    lpAddresses: {
      2001: '',
      2000: '0xe2ea9a7796ef361c0cf4a02133fee60fdfb5c05b',
    },
    token: serializedTokens.goge,
    quoteToken: serializedTokens.wwdoge
  },
  {
    pid: FARM_USDT_WWDOGE_PID,
    lpSymbol: 'WWDOGE-USDT LP',
    lpAddresses: {
      2001: '',
      2000: '0xb860a143a8ad1de77d0fbd63c91f0b2a42473177',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wwdoge,
    hidden: true
  },
  {
    pid: 5,
    lpSymbol: 'WWDOGE-DOGECOIN LP',
    lpAddresses: {
      2001: '',
      2000: '0xb21775204b5ee9485937990ff1aae0486e6d1559',
    },
    token: serializedTokens.wwdoge,
    quoteToken: serializedTokens.dc
  },
  {
    pid: 6,
    lpSymbol: 'WWDOGE-DOGECOIN LP',
    lpAddresses: {
      2001: '',
      2000: '0xb21775204b5ee9485937990ff1aae0486e6d1559',
    },
    token: serializedTokens.wwdoge,
    quoteToken: serializedTokens.dc
  },
]

export default farms
