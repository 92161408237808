import BigNumber from 'bignumber.js'
import poolsConfig from '../../config/constants/pools'
import { SerializedPoolConfig } from '../../config/constants/types'
import multicall from '../../utils/multicall'
import masterchefABI from '../../config/abi/masterchef.json'
import { getMasterChefAddress } from '../../utils/addressHelpers'
import { BIG_ZERO } from '../../utils/bigNumber'
import { PoolPublicData } from '../types'

export const fetchPoolsPublicData = async () => {
  return Promise.all(
    poolsConfig.map( async ( poolConfig: SerializedPoolConfig ) => {
      const poolPublicData = await fetchPoolPublicData( poolConfig )
      return { sousId: poolConfig.sousId, ...poolPublicData }
    } ),
  )
}

export const fetchPoolPublicData = async ( pool: SerializedPoolConfig ): Promise<PoolPublicData> => {
  const { sousId, isMasterPool } = pool

  const [ info, totalAllocPoint ] =
    isMasterPool
      ? await multicall( masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [ sousId ],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
      ] )
      : [ null, null ]

  const allocPoint = info ? new BigNumber( info.allocPoint?._hex ) : BIG_ZERO
  const poolWeight = totalAllocPoint ? allocPoint.div( new BigNumber( totalAllocPoint ) ) : BIG_ZERO
  const withdrawLockPeriod = info ? new BigNumber( info.withdrawLockPeriod?._hex ) : BIG_ZERO

  return {
    withdrawLockPeriod: withdrawLockPeriod.toNumber(),
    multiplier: `${ allocPoint.div( 100 ).toString() }X`,
    poolWeight: poolWeight.toNumber(),
  }
}
