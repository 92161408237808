import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Flex, Heading } from 'uikit'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { useFetchPublicPoolsData, useFetchUserPools, usePools } from 'state/pools/hooks'
import { usePollFarmsPublicData } from 'state/farms/hooks'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import { DeserializedPool } from 'state/types'
import { useUserPoolStakedOnly } from 'state/user/hooks'
import Loading from 'components/Loading'
import PoolCard from './components/PoolCard'
import PoolTabButtons from './components/PoolTabButtons'

const PageWrapper = styled.div`
  background-color: ${ ( { theme } ) => theme.colors.backgroundAlt };
`

const CardLayout = styled( FlexLayout )`
  justify-content: center;
`

const PoolControls = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${ ( { theme } ) => theme.mediaQueries.sm } {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 32px;
    margin-bottom: 0;
  }
`

const NoPoolsMessage = styled.div`
    text-align: center;
    color: ${ ( { theme } ) => theme.colors.textSubtle };
    padding: 16px;
`

const NUMBER_OF_POOLS_VISIBLE = 12

const Pools: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools, userDataLoaded } = usePools()
  const [ stakedOnly, setStakedOnly ] = useUserPoolStakedOnly()
  const [ numberOfPoolsVisible, setNumberOfPoolsVisible ] = useState( NUMBER_OF_POOLS_VISIBLE )
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const chosenPoolsLength = useRef( 0 )

  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [ finishedPools, openPools ] = useMemo( () => partition( pools, ( pool ) => pool.isFinished ), [ pools ] )
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter( ( pool ) => {
        return pool.userData && new BigNumber( pool.userData.stakedBalance ).isGreaterThan( 0 )
      } ),
    [ finishedPools ],
  )
  const stakedOnlyOpenPools = useMemo(
    () =>
      openPools.filter( ( pool ) => {
        return pool.userData && new BigNumber( pool.userData.stakedBalance ).isGreaterThan( 0 )
      } ),
    [ openPools ],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  usePollFarmsPublicData()
  useFetchPublicPoolsData()
  useFetchUserPools( account )

  useEffect( () => {
    if ( isIntersecting ) {
      setNumberOfPoolsVisible( ( poolsCurrentlyVisible ) => {
        if ( poolsCurrentlyVisible <= chosenPoolsLength.current ) {
          return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
        }
        return poolsCurrentlyVisible
      } )
    }
  }, [ isIntersecting ] )

  const showFinishedPools = location.pathname.includes( 'history' )

  let chosenPools: DeserializedPool[]

  if ( showFinishedPools ) {
    chosenPools = stakedOnly ? stakedOnlyFinishedPools : finishedPools
  } else {
    chosenPools = stakedOnly ? stakedOnlyOpenPools : openPools
  }

  chosenPools = chosenPools.slice( 0, numberOfPoolsVisible )
  chosenPoolsLength.current = chosenPools.length

  const cardLayout = (
    <CardLayout>
      { chosenPools.length > 0 ?
        chosenPools.map( ( pool: DeserializedPool ) =>
          <PoolCard key={ pool.sousId } pool={ pool } account={ account }/>,
        ) :
        <NoPoolsMessage>No such pools</NoPoolsMessage>
      }
    </CardLayout>
  )

  return (
    <PageWrapper>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={ [ 'column', null, null, 'row' ] }>
          <Flex flex="1" flexDirection="column" mr={ [ '8px', 0 ] }>
            <Heading comicStyle as="h1" scale="xxl" color="contrast" mb="24px">
              { t( 'Pools' ) }
            </Heading>
            <Heading scale="md" color='white'>
              { t( 'Stake your tokens to earn GOGE rewards!' ) }
            </Heading>
          </Flex>
        </Flex>
      </PageHeader>
      <Page>
        <PoolControls>
          <PoolTabButtons
            stakedOnly={ stakedOnly }
            setStakedOnly={ setStakedOnly }
            hasStakeInFinishedPools={ hasStakeInFinishedPools }
          />
        </PoolControls>
        { account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center" mb="4px">
            <Loading/>
          </Flex>
        ) }
        { cardLayout }
        <div ref={ observerRef }/>
      </Page>
    </PageWrapper>
  )
}

export default Pools
