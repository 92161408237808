import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import masterchefABI from 'config/abi/masterchef.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import serializedTokens from 'config/constants/tokens'
import { getSouschefV2Contract } from '../../utils/contractHelpers'

export const fetchPoolsBlockLimits = async ( argCurrentBlock ) => {
  const poolsWithEnd = poolsConfig.filter( ( p ) => !p.isMasterPool )
  return poolsWithEnd.map( ( cakePoolConfig ) => {
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber( argCurrentBlock - 1000 ).toJSON(),
      endBlock: new BigNumber( argCurrentBlock + 10000 ).toJSON(),
    }
  } )
}

export const fetchPoolsTotalStaking = async () => {
  const nonBnbPools = poolsConfig.filter( ( p ) => p.stakingToken.symbol !== serializedTokens.wwdoge.symbol )
  const bnbPool = poolsConfig.filter( ( p ) => p.stakingToken.symbol === serializedTokens.wwdoge.symbol );

  const callsNonBnbPools = nonBnbPools.map( ( poolConfig ) => {
    return {
      address: getMasterChefAddress(),
      name: 'poolInfo',
      params: [ poolConfig.sousId ],
    }
  } )

  const callsBnbPools = bnbPool.map( ( poolConfig ) => {
    return {
      address: serializedTokens.wwdoge.address,
      name: 'balanceOf',
      params: [ getAddress( poolConfig.contractAddress ) ],
    }
  } )

  const nonBnbPoolsTotalStaked = await multicall( masterchefABI, callsNonBnbPools )
  const bnbPoolsTotalStaked = await multicall( wbnbABI, callsBnbPools )

  return [
    ...nonBnbPools.map( ( p, index ) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber( nonBnbPoolsTotalStaked[index]?.balance?._hex ).toJSON(),
    }) ),
    ...bnbPool.map( ( p, index ) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber( bnbPoolsTotalStaked[index] ).toJSON(),
    }) ),
  ]
}

export const fetchPoolStakingLimit = async ( sousId: number ): Promise<BigNumber> => {
  try {
    const sousContract = getSouschefV2Contract( sousId )
    const stakingLimit = await sousContract.poolLimitPerUser()
    return new BigNumber( stakingLimit.toString() )
  } catch ( error ) {
    return BIG_ZERO
  }
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
): Promise<{ [key: string]: BigNumber }> => {
  const validPools = poolsConfig
    .filter( ( p ) => p.stakingToken.symbol !== serializedTokens.wwdoge.symbol && !p.isFinished )
    .filter( ( p ) => !poolsWithStakingLimit.includes( p.sousId ) )

  // Get the staking limit for each valid pool
  // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
  const stakingLimitPromises = validPools.map( ( validPool ) => fetchPoolStakingLimit( validPool.sousId ) )
  const stakingLimits = await Promise.all( stakingLimitPromises )

  return stakingLimits.reduce( ( accum, stakingLimit, index ) => {
    return {
      ...accum,
      [validPools[index].sousId]: stakingLimit,
    }
  }, {} )
}
