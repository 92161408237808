import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { CardFooter, ExpandableLabel, Flex, HelpIcon, useTooltip } from 'uikit'
import { DeserializedPool } from 'state/types'
import { CompoundingPoolTag, ManualPoolTag } from 'components/Tags'
import ExpandedFooter from './ExpandedFooter'

interface FooterProps {
  pool: DeserializedPool
  account: string
  totalCakeInVault?: BigNumber
}

const ExpandableButtonWrapper = styled( Flex )`
  align-items: center;
  justify-content: space-between;
  button {
    padding: 0;
  }
`

const Footer: React.FC<FooterProps> = ( { pool, account } ) => {
  const { isAutoVault } = pool
  const { t } = useTranslation()
  const [ isExpanded, setIsExpanded ] = useState( false )

  const manualTooltipText = t( 'You must harvest and compound your earnings from this pool manually.\n\nWARNING: Compounding your GOGE will reset your lock time!' )
  const autoTooltipText = t(
    'Any funds you stake in this pool will be automagically harvested and restaked (compounded) for you.',
  )

  const { targetRef, tooltip, tooltipVisible } = useTooltip( isAutoVault ? autoTooltipText : manualTooltipText, {
    placement: 'bottom',
  } )

  return (
    <CardFooter>
      <ExpandableButtonWrapper>
        <Flex alignItems="center">
          { isAutoVault ? <CompoundingPoolTag/> : <ManualPoolTag/> }
          { tooltipVisible && tooltip }
          <Flex ref={ targetRef }>
            <HelpIcon ml="4px" width="20px" height="20px" color="textSubtle"/>
          </Flex>
        </Flex>
        <ExpandableLabel expanded={ isExpanded } onClick={ () => setIsExpanded( !isExpanded ) }>
          { isExpanded ? t( 'Hide' ) : t( 'More' ) }
        </ExpandableLabel>
      </ExpandableButtonWrapper>
      { isExpanded && <ExpandedFooter pool={ pool } account={ account }/> }
    </CardFooter>
  )
}

export default Footer
