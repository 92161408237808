import React from 'react'
import styled from 'styled-components'
import { CalculateIcon, Flex, IconButton, Skeleton, TooltipText, useModal, useTooltip } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import RoiCalculatorModal from 'components/RoiCalculatorModal'
import { DeserializedPool } from 'state/types'
import { getAprData } from 'views/Pools/helpers'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { SWAP_URL } from 'config'

const ApyLabelContainer = styled( Flex )`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

interface AprRowProps {
  pool: DeserializedPool
  stakedBalance: BigNumber
  performanceFee?: number
}

const AprRow: React.FC<AprRowProps> = ( { pool, stakedBalance, performanceFee = 0 } ) => {
  const { t } = useTranslation()
  const {
    stakingToken,
    earningToken,
    isFinished,
    apr,
    earningTokenPrice,
    stakingTokenPrice,
    userData,
    isAutoVault,
  } = pool

  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber( userData.stakingTokenBalance ) : BIG_ZERO

  const tooltipContent = isAutoVault
    ? t( 'APY includes compounding, APR doesn’t. This pool’s GOGE is compounded automatically, so we show APY.' )
    : t( 'This pool’s rewards aren’t compounded automatically, so we show APR' )

  const { targetRef, tooltip, tooltipVisible } = useTooltip( tooltipContent, { placement: 'bottom-start' } )

  const { apr: earningsPercentageToDisplay, autoCompoundFrequency } = getAprData( pool, performanceFee )

  const apyModalLink = stakingToken.address ? `${ SWAP_URL }/swap?outputCurrency=${ stakingToken.address }` : `${ SWAP_URL }/swap`

  const [ onPresentApyModal ] = useModal(
    <RoiCalculatorModal
      earningTokenPrice={ earningTokenPrice }
      stakingTokenPrice={ stakingTokenPrice }
      apr={ apr }
      linkLabel={ t( 'Get %symbol%', { symbol: stakingToken.symbol } ) }
      linkHref={ apyModalLink }
      stakingTokenBalance={ stakedBalance.plus( stakingTokenBalance ) }
      stakingTokenSymbol={ stakingToken.symbol }
      earningTokenSymbol={ earningToken.symbol }
      autoCompoundFrequency={ autoCompoundFrequency }
      performanceFee={ performanceFee }
    />,
  )

  return (
    <Flex alignItems="center" justifyContent="space-between">
      { tooltipVisible && tooltip }
      <TooltipText ref={ targetRef }>{ isAutoVault ? `${ t( 'APY' ) }:` : `${ t( 'APR' ) }:` }</TooltipText>
      { typeof earningsPercentageToDisplay !== 'undefined' || isFinished ? (
        <ApyLabelContainer alignItems="center" onClick={ onPresentApyModal }>
          <Balance
            fontSize="16px"
            isDisabled={ isFinished }
            value={ isFinished ? 0 : earningsPercentageToDisplay }
            decimals={ 2 }
            unit="%"
            onClick={ onPresentApyModal }
          />
          { !isFinished && (
            <IconButton variant="text" scale="sm">
              <CalculateIcon color="textSubtle" width="18px"/>
            </IconButton>
          ) }
        </ApyLabelContainer>
      ) : (
        <Skeleton width="82px" height="32px"/>
      ) }
    </Flex>
  )
}

export default AprRow
