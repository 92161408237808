import BigNumber from 'bignumber.js'
import React from 'react'
import { CardBody, CardRibbon, Flex, Text } from 'uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import AprRow from './AprRow'
import { StyledCard } from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeader from './StyledCardHeader'
import CardActions from './CardActions'
import { DeserializedPool } from '../../../../state/types'
import LockTimeTag from '../../../../components/LockTimeTag'

const PoolCard: React.FC<{ pool: DeserializedPool; account: string }> = ( { pool, account } ) => {
  const {
    stakingToken,
    earningToken,
    isFinished,
    userData,
    isMasterPool,
    withdrawLockPeriod,
  } = pool

  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber( userData.stakedBalance ) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt( 0 )

  return (
    <StyledCard
      isFinished={ isFinished && !isMasterPool }
      ribbon={ isFinished && <CardRibbon variantColor="textDisabled" text={ t( 'Finished' ) }/> }
    >
      <StyledCardHeader
        isStaking={ accountHasStakedBalance }
        earningToken={ earningToken }
        stakingToken={ stakingToken }
        isFinished={ isFinished && !isMasterPool }
      />
      <CardBody>
        <AprRow pool={ pool } stakedBalance={ stakedBalance }/>

        <Flex alignItems="center" justifyContent="space-between" mt="0.5rem">
          <Text>Withdraw Lock:</Text>
          <LockTimeTag seconds={ withdrawLockPeriod }/>
        </Flex>

        <Flex mt="24px" flexDirection="column">
          { account ? (
            <CardActions pool={ pool } stakedBalance={ stakedBalance }/>
          ) : (
            <>
              <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                { t( 'Start earning' ) }
              </Text>
              <ConnectWalletButton/>
            </>
          ) }
        </Flex>
      </CardBody>
      <CardFooter pool={ pool } account={ account }/>
    </StyledCard>
  )
}

export default PoolCard
