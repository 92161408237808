import React from 'react'
import styled from 'styled-components'
import { Flex, Text, WarningIcon } from 'uikit'
import { space, SpaceProps } from 'styled-system'

const ContentWrapper = styled( Flex )`
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  background: #FFFFFF;
  border-radius: 12px;
  ${ space }
`

export interface ModalFooterNoteProps extends SpaceProps {
  title?: string
  iconColor?: string
}

const ModalFooterNote: React.FC<ModalFooterNoteProps> = ( props ) => {
  const { title, iconColor, children, ...otherProps } = props

  return (
    <ContentWrapper { ...otherProps }>
      <WarningIcon color={ iconColor } mr="4px"/>
      <Flex flexDirection="column">
        { title && <Text fontSize="14px" color="textSubtle" bold>{ title }</Text> }
        { children }
      </Flex>
    </ContentWrapper>
  )
}

ModalFooterNote.defaultProps = {
  iconColor: 'text',
}

export default ModalFooterNote