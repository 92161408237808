import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'
import { ChainId } from './constants/chain'

BigNumber.config( {
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
} )

export const FARM_USDT_WWDOGE_PID = 2;
export const FARM_GOGE_WWDOGE_PID = 3;

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const BSC_BLOCK_TIME = 2

export const GOGE_PER_BLOCK = 24940.31034745

export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = GOGE_PER_BLOCK * BLOCKS_PER_YEAR

export const BASE_URL = 'https://farms.goge.dog/'

export const SWAP_URL = 'https://dogeswap.org/#'
export const BASE_ADD_LIQUIDITY_URL = `${ SWAP_URL }/add`

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://explorer.dogechain.dog',
  [ChainId.TESTNET]: 'https://explorer.dogechain.dog',
}
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET]

export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow( 18 )
export const DEFAULT_GAS_LIMIT = 1_000_000