import React from 'react'
import { Button, Flex, Heading, Skeleton, Text, useModal } from 'uikit'
import BigNumber from 'bignumber.js'
import { Token } from '@pancakeswap/sdk'
import { useWeb3React } from '@web3-react/core'
import { useTheme } from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { nowEpoch } from 'utils'
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import Balance from 'components/Balance'
import CountdownTimer from 'components/CountdownTimer'
import CollectModal from '../Modals/CollectModal'

interface HarvestActionsProps {
  earnings: BigNumber
  earningToken: Token
  sousId: number
  isMasterPool: boolean
  earningTokenPrice: number
  isBnbPool: boolean
  isLoading?: boolean
  userHarvestLockedUntil: number
  disableDeposits?: boolean
  stakedBalance?: BigNumber
}

const HarvestActions: React.FC<HarvestActionsProps> = ( props ) => {
  const {
    earnings,
    earningToken,
    sousId,
    isMasterPool,
    isBnbPool,
    earningTokenPrice,
    userHarvestLockedUntil,
    disableDeposits,
    stakedBalance,
    isLoading = false,
  } = props

  const { t } = useTranslation()
  const { account } = useWeb3React()
  const theme = useTheme()

  const earningTokenBalance = getBalanceNumber( earnings, earningToken.decimals )
  const formattedBalance = formatNumber( earningTokenBalance, 5, 5 )
  const earningTokenDollarBalance = getBalanceNumber( earnings.multipliedBy( earningTokenPrice ), earningToken.decimals )
  const fullBalance = getFullDisplayBalance( earnings, earningToken.decimals )
  const hasEarnings = earnings.toNumber() > 0
  const isCompoundPool = isMasterPool

  const userHarvestLockTimerEnded = nowEpoch() >= userHarvestLockedUntil

  const [ onPresentCollect ] = useModal(
    <CollectModal
      formattedBalance={ formattedBalance }
      fullBalance={ fullBalance }
      earningToken={ earningToken }
      earningsDollarValue={ earningTokenDollarBalance }
      isMasterPool={ isMasterPool }
      sousId={ sousId }
      isBnbPool={ isBnbPool }
      isCompoundPool={ isCompoundPool }
      disableDeposits={ disableDeposits }
      stakedBalance={stakedBalance}
    />,
  )

  return (
    <Flex justifyContent="space-between" alignItems="center" mb="16px">
      <Flex flexDirection="column">
        { isLoading ? (
          <Skeleton width="80px" height="48px"/>
        ) : (
          <>
            { hasEarnings ? (
              <>
                <Balance bold fontSize="20px" decimals={ 5 } value={ earningTokenBalance }/>
                { earningTokenPrice > 0 && (
                  <Balance
                    display="inline"
                    fontSize="12px"
                    color="textSubtle"
                    decimals={ 2 }
                    prefix="~"
                    value={ earningTokenDollarBalance }
                    unit=" USD"
                  />
                ) }
              </>
            ) : (
              <>
                <Heading color="textDisabled">0</Heading>
                <Text fontSize="12px" color="textDisabled">
                  0 USD
                </Text>
              </>
            ) }
          </>
        ) }
      </Flex>
      <Flex flexDirection="column" alignItems="center" justifyContent="flex-start">
        <Button disabled={ !hasEarnings || !userHarvestLockTimerEnded } onClick={ onPresentCollect }>
          { isCompoundPool ? t( 'Collect' ) : t( 'Harvest' ) }
        </Button>
        { account && userHarvestLockedUntil > 0 && (
          <CountdownTimer
            timestamp={ userHarvestLockedUntil }
            mt="4px"
            p="2px 4px"
            background={ theme.colors.dropdown }
            iconWidth="12px"
            fontSize="12px"
            textColor="textDisabled"
          />
        ) }
      </Flex>
    </Flex>
  )
}

HarvestActions.defaultProps = {
  userHarvestLockedUntil: 0,
}

export default HarvestActions
