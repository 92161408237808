import React from 'react'
import styled from 'styled-components'
import { formatNumber, getBalanceAmount, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import { Button, Flex, Link, LinkExternal, MetamaskIcon, Skeleton, Text, TimerIcon, useModal } from 'uikit'
import BigNumber from 'bignumber.js'
import { BASE_BSC_SCAN_URL } from 'config'
import { useBlock } from 'state/block/hooks'
import { DeserializedPool } from 'state/types'
import { getAddress } from 'utils/addressHelpers'
import { registerToken } from 'utils/wallet'
import { getBscScanLink } from 'utils'
import Balance from 'components/Balance'
import { getPoolBlockInfo } from 'views/Pools/helpers'
import Divider from '../../../../Farms/components/Divider'
import EmergencyWithdrawModal from '../Modals/EmergencyWithdrawModal'

interface ExpandedFooterProps {
  pool: DeserializedPool
  account: string
}

const ExpandedWrapper = styled( Flex )`
  svg {
    height: 14px;
    width: 14px;
  }
`

const ExpandedFooter: React.FC<ExpandedFooterProps> = ( { pool, account } ) => {
  const { t } = useTranslation()
  const { currentBlock } = useBlock()

  const {
    stakingToken,
    stakingTokenPrice,
    earningToken,
    totalStaked,
    startBlock,
    endBlock,
    stakingLimit,
    contractAddress,
  } = pool

  const tokenAddress = earningToken.address || ''
  const poolContractAddress = getAddress( contractAddress )
  const isMetaMaskInScope = !!window.ethereum?.isMetaMask

  let poolLiquidityUsd = null
  if (
    totalStaked && totalStaked.gt( 0 ) &&
    stakingTokenPrice && stakingTokenPrice > 0
  ) {
    poolLiquidityUsd = getBalanceAmount(
      new BigNumber( totalStaked ).multipliedBy( stakingTokenPrice ),
      stakingToken.decimals,
    )
  }

  const {
    shouldShowBlockCountdown,
    blocksUntilStart,
    blocksRemaining,
    hasPoolStarted,
    blocksToDisplay,
  } = getPoolBlockInfo( pool, currentBlock )

  const [ onPresentEmergencyWithdraw ] = useModal(
    <EmergencyWithdrawModal pool={ pool }/>,
  )

  const getTotalStakedBalance = () => {
    return getBalanceNumber( totalStaked, stakingToken.decimals )
  }

  return (
    <ExpandedWrapper flexDirection="column">
      <Flex mb="2px" justifyContent="space-between" alignItems="center">
        <Text small>{ t( 'Total GOGE Staked' ) }:</Text>
        <Flex alignItems="flex-start">
          { totalStaked && totalStaked.gte( 0 ) ? (
            <Balance small value={ getTotalStakedBalance() } decimals={ 0 }/>
          ) : (
            <Skeleton width="90px" height="21px"/>
          ) }
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb="0.5rem">
        <Text small>{ t( 'Total Liquidity' ) }:</Text>
        { poolLiquidityUsd ? <Text small>${ formatNumber( poolLiquidityUsd.toNumber() ) }</Text> : <Skeleton width={ 75 } height={ 25 }/> }
      </Flex>
      { stakingLimit && stakingLimit.gt( 0 ) && (
        <Flex mb="2px" justifyContent="space-between">
          <Text small>{ t( 'Max. stake per user' ) }:</Text>
          <Text small>{ `${ getFullDisplayBalance( stakingLimit, stakingToken.decimals, 0 ) } ${ stakingToken.symbol }` }</Text>
        </Flex>
      ) }
      { shouldShowBlockCountdown && (
        <Flex mb="2px" justifyContent="space-between" alignItems="center">
          <Text small>{ hasPoolStarted ? t( 'Ends in' ) : t( 'Starts in' ) }:</Text>
          { blocksRemaining || blocksUntilStart ? (
            <Flex alignItems="center">
              <Link external href={ getBscScanLink( hasPoolStarted ? endBlock : startBlock, 'countdown' ) }>
                <Balance small value={ blocksToDisplay } decimals={ 0 } color="primary"/>
                <Text small ml="4px" color="primary" textTransform="lowercase">
                  { t( 'Blocks' ) }
                </Text>
                <TimerIcon ml="4px" color="primary"/>
              </Link>
            </Flex>
          ) : (
            <Skeleton width="54px" height="21px"/>
          ) }
        </Flex>
      ) }
      { !pool.isMasterPool && (
        <Flex mb="2px" justifyContent="flex-end">
          <LinkExternal href={ earningToken.projectLink } bold={ false } small>
            { t( 'View Project Site' ) }
          </LinkExternal>
        </Flex>
      ) }
      { poolContractAddress && (
        <Flex mb="2px" justifyContent="flex-end">
          <LinkExternal
            href={ `${ BASE_BSC_SCAN_URL }/address/${ poolContractAddress }` }
            bold={ false }
            small
          >
            { t( 'View Contract' ) }
          </LinkExternal>
        </Flex>
      ) }
      { account && isMetaMaskInScope && tokenAddress && (
        <Flex justifyContent="flex-end">
          <Button
            variant="text"
            p="0"
            height="auto"
            onClick={ () => registerToken( tokenAddress, earningToken.symbol, earningToken.decimals ) }
          >
            <Text color="primary" fontSize="14px">
              { t( 'Add to Metamask' ) }
            </Text>
            <MetamaskIcon ml="4px"/>
          </Button>
        </Flex>
      ) }
      { account && (
        <>
          <Divider style={ { marginTop: '1rem', marginBottom: '1rem' } }/>
          <Flex justifyContent="flex-end">
            <Button
              variant="text"
              p="0"
              height="auto"
              onClick={ onPresentEmergencyWithdraw }
            >
              <Text color="failure" fontSize="14px">
                { t( 'Emergency withdraw' ) }
              </Text>
            </Button>
          </Flex>
        </>
      ) }
    </ExpandedWrapper>
  )
}

export default React.memo( ExpandedFooter )
