import React from 'react'
import { ImageProps, TokenImage as UIKitTokenImage, TokenPairImage as UIKitTokenPairImage, TokenPairImageProps as UIKitTokenPairImageProps } from 'uikit'
import tokens from 'config/constants/tokens'
import { Token } from '@pancakeswap/sdk'

interface TokenPairImageProps extends Omit<UIKitTokenPairImageProps, 'primarySrc' | 'secondarySrc'> {
  primaryToken: Token
  secondaryToken: Token
}

const getImageUrlFromToken = ( token: Token ) => {
  const address = token.symbol === tokens.wwdoge.symbol ? tokens.wwdoge.address : token.address
  return `/images/tokens/${ address }.png`
}

export const TokenPairImage: React.FC<TokenPairImageProps> = ( { primaryToken, secondaryToken, ...props } ) => {
  return (
    <UIKitTokenPairImage
      primarySrc={ getImageUrlFromToken( primaryToken ) }
      secondarySrc={ getImageUrlFromToken( secondaryToken ) }
      { ...props }
    />
  )
}

interface TokenImageProps extends ImageProps {
  token: Token
}

export const TokenImage: React.FC<TokenImageProps> = ( { token, ...props } ) => {
  return <UIKitTokenImage src={ getImageUrlFromToken( token ) } { ...props } />
}
