import React, { ChangeEvent } from 'react'
import { Box } from '../Box'
import { BarBackground, BarProgress, BunnySlider, SliderLabel, SliderLabelContainer, StyledInput } from './styles'
import SliderProps from './types'

const Slider: React.FC<SliderProps> = ( {
                                          name,
                                          min,
                                          max,
                                          value,
                                          onValueChanged,
                                          valueLabel,
                                          step = 'any',
                                          disabled = false,
                                          ...props
                                        } ) => {
  const handleChange = ( { target }: ChangeEvent<HTMLInputElement> ) => {
    onValueChanged( parseFloat( target.value ) )
  }

  const progressPercentage = (value / max) * 100
  const isMax = value === max
  let progressWidth: string
  if ( progressPercentage <= 10 ) {
    progressWidth = `${ progressPercentage + 0.5 }%`
  } else if ( progressPercentage >= 90 ) {
    progressWidth = `${ progressPercentage - 4 }%`
  } else if ( progressPercentage >= 60 ) {
    progressWidth = `${ progressPercentage - 2.5 }%`
  } else {
    progressWidth = `${ progressPercentage }%`
  }
  const labelProgress = `${ progressPercentage }%`
  const displayValueLabel = isMax ? 'MAX' : valueLabel
  return (
    <Box position="relative" height="48px" { ...props }>
      <BunnySlider>
        <BarBackground disabled={ disabled }/>
        <BarProgress style={ { width: progressWidth } } disabled={ disabled }/>
        <StyledInput
          name={ name }
          type="range"
          min={ min }
          max={ max }
          value={ value }
          step={ step }
          onChange={ handleChange }
          isMax={ isMax }
          disabled={ disabled }
        />
      </BunnySlider>
      { valueLabel && (
        <SliderLabelContainer>
          <SliderLabel progress={ labelProgress }>{ displayValueLabel }</SliderLabel>
        </SliderLabelContainer>
      ) }
    </Box>
  )
}

export default Slider
