import { Colors } from './types'

export const baseColors = {
  failure: '#ed4b4b',
  primaryBright: '#ffa000',
  primaryDark: '#d78200',
  secondary: '#2b2d70',
  success: '#31D0AA',
  warning: '#FFB237',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#2b0831',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  primary: '#c7a156',
  secondary: '#875e0e',
  background: '#fdf28a',
  backgroundDisabled: '#ecebea',
  backgroundAlt: '#fff8eb',
  backgroundAlt2: '#ffffff',
  cardBorder: '#f7efde',
  contrast: '#ffffff',
  dropdown: '#f6f6f6',
  dropdownDeep: '#eeeeee',
  invertedContrast: '#FFFFFF',
  input: '#fff1d6',
  inputSecondary: '#ece1cb',
  tertiary: '#fbf3e4',
  text: '#9d7015',
  textDisabled: '#cec9c0',
  textSubtle: '#ae8532',
  disabled: '#f6f2e9',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #c7a156 0%, #7d5402 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #ffeff9 0%, #fbe5ff 100%)',
    cardHeader: 'linear-gradient(111.68deg, #42c1ff 0%, #06a2ef 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  primary: '#786236',
  secondary: '#b3965b',
  overlay: '#5a513f',
  background: '#352a12',
  backgroundDisabled: '#00000055',
  backgroundAlt: '#2b2415',
  backgroundAlt2: '#2c2a26',
  cardBorder: '#2e1f00',
  contrast: '#FFFFFF',
  dropdown: '#201f1d',
  dropdownDeep: '#18140c',
  invertedContrast: '#251f13',
  input: '#473d29',
  inputSecondary: '#312b21',
  primaryDark: '#a36d00',
  tertiary: '#4d3f23',
  text: '#ebd6ad',
  textDisabled: '#716b60',
  textSubtle: '#d2c5ad',
  disabled: '#9a9793',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #3c1a43 0%, #2f1235 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #571443 0%, #3a052a 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}
